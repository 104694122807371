@import "../variables.scss";


.ListenShow {

  .background-image {
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100vh;

  }

  .NextEpisode {
    img {

      height: 72px;
      width: 72px;
      object-fit: cover;
      object-position: center center;
    }

    &.Desktop img {

      height: 200px;
      width: 200px;
    }
  }

  hr {
    width: 100%;
    height: 2px;
    color: $accent;
    background-color: #F5F5F5;
    border-width: 0;
  }

  .ShowPageContainer {
    position:relative;
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.showGradient {
  background: transparent linear-gradient(
    180deg, 
    #00000000 0px, 
    #000000e5 60vh) 
    0% 
    0% 
    no-repeat 
    padding-box;
}