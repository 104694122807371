@import "./variables.scss";


.TopAlbums {

  display: flex;

  h1 {
    border-width: 0;
  }

  &.Mobile {
    flex-direction: column;
    margin: 19.81px 0 0 0;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .darkGradientMobile {
      background: transparent linear-gradient(180deg, #00000080 0%, black 100%) 0% 0% no-repeat padding-box;
    }

    h1 {
      font-size: 28px;
      line-height: 33.6px;
      margin: 17px 0 17px 0;
    }

    h3 {
      color: white;
    }
  }

  &.Desktop {
    flex-direction: row;
    margin: 91px 50px 120px 0;

    h1 {
      margin: 0 0 86px 0;
      font-size: 48px;
      line-height: 57.6px;
      //    margin-top: 29px;
      text-transform: none;
      height: initial;
    }

    .TopAlbum {
      //    border: 1px solid black;
      color: white;
      background-repeat: no-repeat;
      background-size: cover;


      .darkGradient {
        background: transparent linear-gradient(228deg, #00000066 0%, #000000B3 100%) 0% 0% no-repeat padding-box;
      }

      .lightGradient {
        //border: 1px solid blue;
        background: transparent linear-gradient(90deg, #FFFFFF00 0%, white 100%) 0% 0% no-repeat padding-box;
      }
    }


  }

  .RestOfAlbums {

    &.Mobile {
      width: auto;
      padding: var(--gutter);

      >div:not(:last-child) {
        border-bottom: 1.5px solid $accent;
      }

      img {
        aspect-ratio: 1;
      }
    }

    &.Desktop>div:first-child {
      text-align: right;
      height: 149px;
    }

    &.Desktop {

      >div:not(:last-child):not(:first-child) {
        border-bottom: 2px solid $accent;
      }
    }

  }


}