/*

.gradient:hover {
    color:black;
    background-size: 400% 400%;
    animation: gradient 1s ease infinite;
    height: 100vh;
}

.gradient-1:hover {
	background: linear-gradient(-45deg, #41ff41, #defd36, #ff6dba);
}
.gradient-2:hover {
	background: linear-gradient(-45deg, #defd36,#ff6dba, #ffffa9);
}
.gradient-3:hover {
	background: linear-gradient(-45deg, #0046ff, #ff634e, #1fa0ff);
}

*/
.gradient-text, .gradient-text-hover {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    background-size: 400% 400%;
  }
}
.gradient-text-hover {
  color: initial;
}

.gradient-text-hover:hover {
  color: transparent;
}

a.gradient-hover:hover {
  color: black;
  animation: gradient 4s ease infinite;
  background-size: 400% 400%;
  height: 3vh;
  border-radius: 4px;
}

.gradient-1-hover:hover, .gradient-1 {
  background-image: linear-gradient(-45deg, #41ff41, #defd36, #ff6dba);
}
.gradient-2-hover:hover, .gradient-2 {
  background-image: linear-gradient(-45deg, #defd36, #ff6dba, #ffffa9);
}
.gradient-3-hover:hover, .gradient-3 {
  background-image: linear-gradient(-45deg, #0046ff, #ff634e, #1fa0ff);
}
.gradient-4-hover:hover, .gradient-4 {
  background-image: linear-gradient(-45deg, #e1ff00, #1fa0ff, #ff634e);
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


a.color-underline:hover{
  
  text-decoration: underline;
  text-decoration-color: white;
}