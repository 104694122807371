@import-normalize;
@import "variables.scss";

.Landing {

  &:not(.Desktop){
    margin-top:0;
  }
  .Magazine {

    background-color: $accent;
    margin: 0;
    padding: 22px 16px;
    background-size: cover;
    position: relative;

    &.Desktop {
      padding: 50px 50px 0 50px;
    }
  }

  .FrontPageArticle {

    padding-bottom: 72px;


    .image {
      position: relative;
      left: -16px;
      width: 100vw;
      height: 100vw;
    }

    .description {
      margin-top: 11px;
    }

    &.Desktop {
      height: calc($twoThirdsPageWidthDesktop / 1.5);
      margin-top: var(--bar-height);
      margin-bottom: var(--bar-height);
      padding-bottom: 0;
      display: flex;
      flex-direction: row-reverse;

      .FrontPageArticleText {
        width: $oneThirdPageWidthDesktop;
        position: relative;
        padding-right: 16px;
        h3 {
          margin: 0;
        }
      }

      .image {
        position: initial;
        width: $twoThirdsPageWidthDesktop;
        height: calc(($twoThirdsPageWidthDesktop )/ 1.5);
      }

    }

  }


  .LatestFromDiscorder {
    margin-top: 44px;

    &.Desktop {
      margin-top: 71.67px;

      h1 {
        margin-bottom: 24px;
      }
    }
  }


}