@import "../variables.scss";

.SearchResults {



  h1 {
    opacity: 0.4;
    cursor: pointer;

    &.selected {
      opacity: 1;
    }

    span {
      font-weight: normal;
    }
  }





  .results {

    // border: 1px solid green;
    // height: 300px;
    hr {
      background-color: #F5F5F5;
      border: none;
      height: 2px;
      width: 100%;
      margin: 0;
      margin-top: 18px; //  gap should be: Desktop() ? '36px' : '18px';
      margin-bottom: 18px;
    }

    &.Desktop hr {
      margin-top: 36px;
      margin-bottom: 36px;
    }
  }
}