@import-normalize;
@import "variables.scss";
@import "dev.scss";
@import "gradients.scss";


@font-face {
  font-family: 'custom';
  src: url('/NeueHaasLight.ttf') format("truetype");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: polymath, sans-serif;
  font-style: normal;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
.Bar {
  background-color: #FFF;
}

li {
  list-style-type: none;
}

a,
a:active,
a:visited {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img {
  object-fit: contain;
  //  max-width: 80vw;
}

hr {
  // margin: 0;
}

.PageContent {

  &:not(.Desktop)>*>*:not(.meets-edge) {
    margin: 0 16px;
  }
  
  &.Desktop>*>*:not(.meets-edge):not(.centre-column) {
    margin-left: 50px;
    margin-right: 50px;
  }

  &.ProgramGuide>*>*:not(.meets-edge):not(.centre-column) {
    margin-left:25px;
  }

  >*:first-child {
    margin-top: 12px;
    min-height: 50vh;

  }

  &.Desktop>*:first-child {
    margin-top: 24px; // default: can be overridden by first child of this
    min-height: 50vh;

    &.full-bleed {
      margin-top: 0;
    }
  }


  padding-top: var(--bar-height);
  min-height: calc(100vh - 29em);
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  /*page height - footer height - bar height - bar padding */
  z-index: 0;

}


.image {

  background-size: cover;
  background-position: center;
}


.white-line {
  background: black;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;

  div {
    background: white;
    width: $mobileWidth;
    height: 1px;
    position: relative;
    bottom: 1px;
  }

  &.Desktop div {
    width: 100%;
  }
}

a.grey,
.grey {
  color: var(--inactive-grey);
}


button {
  cursor: pointer;
  font-size: 10px;

  &.Desktop {
    font-size: 13px;
  }

  line-height: 16.9px;
  font-weight: bold;
  color: white;
  padding: 8px 16px;
  text-transform: uppercase;
  background-color: black;
  border-radius: 0;
  border-width: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &.light {
    background: white;
    color: black;
  }

  &.inline {
    margin: 0;
    position: initial;
    padding: 5px 10px;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.upper {
  text-transform: uppercase;
}

.top-border {
    border-top: 2px solid black;
  //border-top: 2px solid;
//  border-image: linear-gradient(to right,  #41ff41 0%,#defd36 48%,#ff6dba 99%) 1; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-weight: bold;
  text-transform: uppercase;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#41ff41+0,defd36+48,ff6dba+99 */


}



h1,
a.h1 {
  font-size: 16px;
  line-height: 20.8px;
  font-weight: bold;
  text-transform: uppercase;
  border-top: 2px solid black;
  margin: 0;

  &.title {
    border-width: 0;
    font-size: 20px;
    line-height: 24px;

    &.Desktop {
      font-size: 48px;
      line-height: 72px;
      text-transform: none;
    }
  }
}




.size48 {
  font-size: 48px;
  line-height: 48px;
}

.size48-tall {
  font-size: 48px;
  line-height: 57.6px;
}

.size28 {
  font-size: 28px;
  line-height: 33.6px;
}

.size25 {
  font-size: 25px;
  line-height: 32.5px;
}

.size20 {
  font-size: 20px;
  line-height: 24px
}


h2,
a.h2 {
  margin: 0;
  font-weight: normal;

  &.title {
    font-size: 28px;
    line-height: 33.6px;
  }

  //  text-transform: uppercase;
}


h4,
a.h4 {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: initial;
}

.size16-tall {
  font-size: 16px;
  line-height: 20.8px;
}

h3,
a.h3 {
  font-size: 16px;
  line-height: 20.8px;
  font-weight: bold;
  text-transform: uppercase;

  &.white {
    border-color: white;
  }
}

.size16 {
  font-size: 16px;
  line-height: 19.2px;
}


.size13 {
  font-size: 13px;
  line-height: 16.9px
}

h5,
a.h5 {

  font-size: 13px;
  line-height: 16.9px;
  font-weight: bold;
  text-transform: uppercase;
  color: $accent2;

  &.light {
    color: black;
    font-weight: normal;
  }
}

.size10 {
  font-size: 10px;
  line-height: 16.9px;
}

input {
  font-weight: bold;
  text-transform: uppercase;
  border-width: 0 0 2px 0;
  border-color: black;
}

input:focus {
  //border-width: 0px;
  outline: none;
}

div.error {
  margin-left: 3vw;
}

.centre-column {
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;

  &.Desktop {
    width: calc(50vw - 50px);
  }
}