.blog-post {
    max-width: 920px; 
    margin: 0 auto;
}

.blog-header h1{
    border-top: unset;
    text-transform: capitalize;
    font-size: 48px;
    line-height: 1.25;
}

.blog-author {
    font-size: 20px;
    margin-top: 24px;
}

.blog-author span {
    text-transform: capitalize;
}

.blog-featured-image img {
    max-width: 100%;
}   