@import-normalize;
@import "../variables.scss";


.Read,
.ReadColumn {

  .browse-by-column {

    .column:not(:last-child) {
      border-bottom: 2px solid $accent;
    }
  }
}

.Magazine {

  &.Desktop {
    margin-bottom: 72px;
  }

  .latestIssueImageBox {

    background-color: $accent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1;

    &.Desktop {
      width: $oneHalfPageWidthDesktop;
      height: $oneHalfPageWidthDesktop;
    }

  }

  >* {
    z-index: 2;
    position: relative;
  }

  &.Desktop>.DesktopGradientOverlay,
  &.Desktop>.landingPageFlex {
    //align-items: flex-end;
    //height: 880px;

    //      padding-bottom: 166.71px;
    &.landingPageFlex>* {
      //      border: 1px solid red;
      margin-top: 48px;
      margin-bottom: 166.71px;
      max-width: 443px;
      max-height: 419px;
      flex-shrink: 1;

      &.latestIssueUI {
        margin-left: 16px;
        margin-right: 16px;
        //        border: 1px solid green;
        flex-shrink: 1;
      }

      &:nth-child(3) {
        //        border: 1px solid pink;
        flex-grow: 1;
      }

      &:nth-child(4) {
        flex-shrink: 1;
        //       border: 1px solid blue;
        //width: 424px;
        //min-width: 424px;
        //height: 100%;
      }
    }
  }

  &.Desktop .DesktopGradientOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: transparent linear-gradient(90deg, #F5F5F5B3 0%, #F5F5F5 50%, #F5F5F5 100%) 0% 0% no-repeat padding-box;

  }

  .cover {
    aspect-ratio: 3/4; //354.85/475.5;
  }

  button {
    margin-top: 38px;
  }

  .past-issues {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }

  &.Desktop .past-issues {
    justify-content: space-between;
  }


}

.article-html-content {
  img.alignleft {
    float: left;
  }

  img.alignright {
    float: right;
  }

  p {
    font-size: 16px;
    line-height: 19.2px;
  }

  &.Desktop p {
    font-size: 20px;
    line-height: 30px;
  }
}