.wp-core-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem; /* Adjust the gap between grid items as needed */
}

.wp-citr-block-group > div[class^="wp-"] {
    margin-bottom: 2rem;
}

.wp-citr-block-group > div[class^="wp-"]:last-child {
    margin-bottom: 5rem;
}

.wp-citr-block-group img {
    display: block;
    max-width: 100%;
    height: auto;
}

.wp-citr-block-group figure {
    margin-left: 0;
    margin-right: 0;
}

.wp-citr-block-group p {
    font-size: 20px;
    line-height: 1.5;
}

.wp-citr-block-group .wp-core-quote p{
    font-size: 22px;
    padding: .25rem 0 .75rem 2rem;
    border-left: 4px solid #000;
    line-height: 1.333;
    font-weight: bold;
}

.wp-citr-block-group .wp-core-quote p::before {
    content: '“';
}

.wp-citr-block-group .wp-core-quote p::after {
    content: '”';
}

/* Image block */
.wp-citr-block-group figcaption {
    margin-top: .5rem;
    text-align: right;
}

/* Tablet */
@media (min-width: 768px) {

    .wp-citr-block-group .wp-core-quote p{
        font-size: 28px;
    }

}

/* Desktop */
@media (min-width: 1280px) {

    .wp-citr-block-group .wp-core-quote {
        position: relative;
        left: -153px;
    }

    .wp-citr-block-group .wp-core-quote p{
        font-size: 48px;
        font-weight: 300;
        border: unset
    }

}