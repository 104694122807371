@import "variables.scss";

header {
  width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 16px #00000014;
  position: fixed;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.Bar {
  

  &.Desktop>* {
    //border: 1px solid red;
    height: 24px; //calc($bar-height - 28px);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    box-sizing: border-box;
  }

  &.Mobile>* {
    //border: 1px solid green;
    height: 36px; //calc($bar-height - 28px);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    box-sizing: border-box;
  }

  .Logo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

}

.NavLink {
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 9px 0 0;
}

a.NavLink:hover {
  opacity: 1 !important;
}

.search-icon:hover * {
  opacity: 1 !important;
}

.DesktopSubNavLinks a:hover {
  opacity: 1 !important;
}