@import "../variables.scss";


.Tile {


  &.show.detail,
  &.episode.detail {
    width: 100%;
    height: auto;
  }

  &.show.detail {
    padding-top: 390px;
    padding-bottom: 57px;
  }

  &.episode.detail {
    padding-top: 120px;
    padding-bottom: 0px;
  }

  &.Desktop {
    &.show.detail {
      padding-top: 540px;
      padding-bottom: 110px;
    }

    &.episode.detail {
      padding-top: 216px;
      padding-bottom: 57px;
    }
  }

  &.show,
  &.episode {

    .episodeImage {
      width:50%;
      object-fit: cover;
      object-position: center center;
      aspect-ratio: 1;
    }

    .subtitle,
    .description {
      font-size: 16px;
      line-height: 19.2px;

      div {
        margin-right: 20px;
        display: inline;
      }

      table {
        display: none;
        margin-top: 24px;
        font-size: 18px;
      }
    }

    .description {
      margin-top: 19px
    }

    .tags {
      margin-top: 36px;
    }

    .socials-and-button {
      margin-top: 36px;
    }

    &.Desktop {

      .episodeImage {
        width: calc( calc(100vw - 50px) /6);
      }

      &.detail {
        width: 100%;
        height: auto;
      }

      .title,
      .description {
        font-size: 28px;
        line-height: 33.6px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 24px;

        div {
          margin-right: 36px;
        }
      }

      .description,
      .tags {
        margin-top: 34px;
      }

      .socials-and-button {
        margin-top: 54px;

      }

      .socials {
        margin-top: 0;
      }

    }
  }


  &:not(.detail) {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.show {
      //width: 294px;
      //height: 294px;
      font-size: 16px;
      line-height: 19.2px;


      >div {
        padding: 19px 18px;
      }
    }

    &.episode {
      width: $mobileWidth;
      aspect-ratio: 1;

      &.Desktop {
        width: calc(calc(calc(100vw - 100px) * 0.25) - 16.9px);
        aspect-ratio: 1;
      }

      >div {
        padding: 13px 13px;
      }
    }

    &.show,
    &.episode {

      &.Desktop {
        >div {
          padding: 12px 16px;
        }

        .tags {
          margin-top: 32px;
          margin-top: 7.22%;
        }
      }

      >div.gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, black 100%) 50% 50% no-repeat padding-box;
        color: white;
        height: 50%;
        margin-top: 50%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: border-box;

      }

    }

  }

  $desktopArticleWidth: calc($oneQuarterPageWidthDesktop - 16px);
  $desktopArticleImageHeight: calc($desktopArticleWidth / 1.5);
  $desktopArticleWordsHeight: calc($desktopArticleImageHeight / 2.68);

  $tallDesktopArticleWidth: calc($oneThirdPageWidthDesktop - 16px);
  $tallDesktopArticleImageHeight: calc($tallDesktopArticleWidth / 0.75);
  $tallDesktopArticleWordsHeight: calc($tallDesktopArticleImageHeight / 4.32);

  &.article {


    &.Desktop:not(.tall) {
      width: $desktopArticleWidth;

      //      
      .image {
        width: 100%;
        height: $desktopArticleImageHeight;
      }

      >div {
        height: $desktopArticleWordsHeight;
      }
    }

    &.Desktop.tall {
      width: $tallDesktopArticleWidth;

      //      
      .image {
        width: 100%;
        height: $tallDesktopArticleImageHeight;
      }
    }

    &.Mobile {
      &:last-child {
        border-width: 0 !important;
      }

      .image {
        height: calc($mobileWidth * 0.666);
      }

      &.tall {
        .image {
          width: 100%;
          height: 392px;
        }
      }
    }
  }

  &.issue {
    width: calc(50% - 8px);
  }
}