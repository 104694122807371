.HorizontalScroller {

  >div {


    >* {
      flex-grow: 0;
      flex-shrink: 0;
//      width: 100%;
    }
  }
}

.HorizontalScroller div {
  //  max-width: 400px;
  //  max-height: 200px;
  //  border: 1px solid gray;

  //  overflow-x: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.HorizontalScroller div::-webkit-scrollbar {
  /* WebKit */
  width: 0px;
}