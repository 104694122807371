.Footer {
  padding: 13px 16px;
  height: 671px;
  background-color: black;
  color: azure;
  display: flex;
  flex-direction: column;
  position:relative;
  z-index: 2;

  a,
  a:visited,
  a:active {
    color: azure;
  }

  &.Desktop {
    padding: 32px 50px 72px 50px;
    height: 402px;
  }

}

.Footer-Logos {
  padding-bottom: 13px;

  &.Desktop {
    padding-bottom: 72px;
  }

  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.Footer-Links {
  height: 90%;
  display: flex;
  padding-top: 52px;
  flex-direction: row;
  flex-wrap: wrap;

  &.Desktop {
    padding-top: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  div:not(:first-child) {
    margin: 0 0 0 0;
  }

  &.Desktop div:not(:first-child) {
    margin: 0 0 0 50px;
  }

  h5 {
    color: white;
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 0;
  }

  li {
    font-size: 13px;
    line-height: 20.8px;
  }

  &.Desktop h5,
  &.Desktop li {
    font-size: 16px;
    line-height: 24.8px;
  }

  div:not(:last-child) {
    /* background-color: blue;*/
    min-width: 40vw;
    max-width: 45vw;
    max-height: 40%;

    line-height: 1.5em;
  }

  &.Desktop div:not(:last-child) {
    min-width: initial;
    max-width: initial;
    max-height: initial;
    flex: 0 1 auto;
  }

  div:last-child {
    max-height: 7em;
    /*  background-color: darkblue;*/
    font-size: 0.75em;
    display: flex;
    flex-direction: column;
    min-width: 90vw;
  }

  &.Desktop div:last-child {
    flex-grow: 2;
    align-items: flex-end;
    min-width: initial;
  }

  &.Desktop div:last-child p {
    max-width: 32em;
  }
}