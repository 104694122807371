.get-involved {

    background-color: var(--color-primary);
    color: var(--color-white);
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.get-involved__ticker {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: .25rem 0;
}

.get-involved__content {
    max-width: 920px;
    padding: 70px 0;
    margin: 0 auto;
}

.get-involved__content h4 {
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 1.2;
}

.get-involved__content h4:last-child {
    text-align: right;
}


.get-involved__content img {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.get-involved__button--large {
    padding: 22px 32px;
    font-size: 26px;
    margin-top: 32px;;
}