
$accent: #F5F5F5;
$accent2: #939393;
$light-grey: #F6F6F6;
$mobileWidth: calc(100vw - 32px);
$oneThirdPageWidthDesktop: calc(calc(100vw - 100px) * 0.3333);
$twoThirdsPageWidthDesktop: calc(calc(100vw - 100px) * 0.6666);
$oneQuarterPageWidthDesktop: calc(calc(100vw - 100px) * 0.25);
$oneHalfPageWidthDesktop: calc(calc(100vw - 100px) * 0.5);

html {
  --minWidthForDesktop: 800px;

  --bar-height: 62px;
  --accent: #F5F5F5;
  --accent2: #aeaeae;
  --light-grey: #F6F6F6;
  --legible-grey: #707070;
  --inactive-grey: #b0b0b0;
  --accent3: #ea5f3f;
  --mobileWidth: calc(100vw - 32px);
  --oneThirdPageWidthDesktop: calc(calc(100vw - 100px) * 0.3333);
  --twoThirdsPageWidthDesktop: calc(calc(100vw - 100px) * 0.6666);
  --oneQuarterPageWidthDesktop: calc(calc(100vw - 100px) * 0.25);
  --oneHalfPageWidthDesktop: calc(calc(100vw - 100px) * 0.5);

  --margin: 16px;
  --gutter: 16px;
  --numcolumns: 6;
  --columWidthMobile: calc(100vw - 32px);

}

@media screen and (min-width: 800px) {
  html {
    --bar-height: 72px;
    --margin: 50px;
    --gutter: 16px;
    --numcolumns: 12;
  }
}

html {
  --columnsum: calc(100vw - calc(5 * var(--gutter) - calc( 2 * var(--margin))) );
  --column: calc(var(--columnsum) / var(--numcolumns));
  --halfgutter: calc(var(--gutter) / 2);
}
* {
  scroll-margin-top: calc(var(--bar-height) + 0.75em);
}